export default (function orderModal() {
    const orderModalObj = {
        fileLoadCheck() {
            $(document).on('change', '.js--file_input', function () {
                const fileName = this.files[0].name;
                $('.js--file_text').text(fileName);
                $('#orderModal .error_text').removeClass('error');
            })
        },
        send() {
            const $form = $(".js--order_call");
            $form.submit(function (e) {
                e.preventDefault();
                if (this.checkValidity()) {
                    let $errorTextformet = $('.js--error_text--format');
                    let $errorTextLength = $('.js--error_text--length');
                    let formData = new FormData(this);
                    let idCombinationValue = $('#idCombination').val() !== undefined ? $('#idCombination').val() : '';
                    const $successText = $('.order_modal__success');

                    formData.append('productId', $(this).data('prod-id'));
                    formData.append('combinationId', idCombinationValue);

                    $.ajax({
                        url: '/modules/ffcustomorders/ajax.php',
                        type: 'POST',
                        data: formData,
                        success: function (data) {
                            const response = JSON.parse(data);
                            if (response.error === 1) {
                                $errorTextformet.addClass('error')
                            } else if (response.error === 2) {
                                $errorTextLength.addClass('error')

                            } else {
                                $successText.removeAttr('hidden');
                                gtag('event', 'Отправка формы', {
                                    'event_category': 'form',
                                    'event_action': 'otpravka'
                                });
                                setTimeout(function () {
                                    $successText.attr('hidden', true);
                                    $('#orderModal').modal('hide');
                                    $form[0].reset();
                                    $form.removeClass('was_validated')
                                }, 2000)
                            }
                        },
                        cache: false,
                        contentType: false,
                        processData: false
                    });
                };
                $(this).addClass('was_validated')

            });
        },
        findProdId() {
            $('.standard_prod__order').on('click', function () {
                $('.js--order_call').attr('data-prod-id', $(this).closest('[data-prod-id]').data('prod-id'))

            });
            $('.order_btn ').on('click', function () {
                $('.js--order_call').attr('data-prod-id', $(this).closest('[data-prod-id]').data('prod-id'))

            })
        }
    };
    $(document).ready(function () {
        orderModalObj.fileLoadCheck();
        orderModalObj.send();
        orderModalObj.findProdId();
    })
})()