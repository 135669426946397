export default (function address() {
    const AddressObj = {
        fullMap: '',
        send() {
            const $form = $(".js--contact_form ");
            $form.submit(function (e) {
                e.preventDefault();
                if (this.checkValidity()) {
                    const formData = $form.serialize();
                    const $successText = $('.contact_modal__success');

                    $.ajax({
                        url: $form.attr('action'),
                        type: 'POST',
                        data: formData + `g-recaptcha-response:${grecaptcha.getResponse()}`,
                        success: function (data) {
                            const response = JSON.parse(data);
                            if (response.result === 'success') {
                                $successText.removeAttr('hidden');
                                setTimeout(function () {
                                    $('#contactModal').modal('hide');
                                    $successText.attr('hidden', true);
                                    $form[0].reset();
                                    $form.removeClass('was_validated')
                                }, 2000)
                            }
                        },
                        error: function (data) {
                            console.log(data);
                        }
                    })
                }
                $(this).addClass('was_validated')
            })
        },
        init() {
            if ($('.address__map').length) {
                const $mapEl = $('.address__map[id^="addressMap_"]');
                let map;
                let markersData = [];
                $mapEl.each(function (i, element) {
                    const currentLat = $(element).data('lat');
                    const currentLng = $(element).data('lng');
                    const tooltipAddress =$(this).closest('.address').find('.address__building').text();
                    let location = {lat: currentLat, lng: currentLng, tooltipAddress};

                    // One
                    map = new google.maps.Map(
                        document.getElementById(`addressMap_${i}`), {
                            zoom: 14,
                            center: location,
                            disableDefaultUI: true
                        });
                    let infowindow = new google.maps.InfoWindow({
                        content: tooltipAddress
                    });

                    let marker = new google.maps.Marker({
                        position: location,
                        map: map,
                        animation: google.maps.Animation.DROP,
                    });
                    marker.addListener('click', toggleBounce);
                    function toggleBounce() {
                        if (marker.getAnimation() !== null) {
                            marker.setAnimation(null);
                        } else {
                            marker.setAnimation(google.maps.Animation.BOUNCE);
                        }
                    }
                    marker.addListener('click', function () {
                        infowindow.open(map, marker);
                    });


                    // All
                    markersData.push(location);
                    AddressObj.fullMap = new google.maps.Map(
                        document.getElementById(`full_map`), {
                            zoom: 14,
                            center: markersData[0],
                            disableDefaultUI: true
                        });

                    for (let i = 0; i < markersData.length; i++) {
                        let infowindow = new google.maps.InfoWindow({
                            content: markersData[i].tooltipAddress
                        });

                        let markers = new google.maps.Marker({
                            position: markersData[i],
                            map: AddressObj.fullMap,
                            animation: google.maps.Animation.DROP,
                        });
                        markers.addListener('click', function () {
                            if (markers.getAnimation() !== null) {
                                markers.setAnimation(null);
                            } else {
                                markers.setAnimation(google.maps.Animation.BOUNCE);
                            }
                        });
                        markers.addListener('click', function () {
                            infowindow.open(AddressObj.fullMap, markers);
                        });
                    }
                })
            }
        },
        descToggle() {
            $('.address__road_desc ').on('click', function (e) {
                $(this).find('.address__road_text').fadeToggle('slow')
            });

            $(document).on('click', function (e) {
                if (!$(e.target).closest('.address__road_desc').length) {
                    $('.address__road_text').fadeOut('slow');
                }
            })
        },
        mapToggle() {
            $('.address__show_map ').on('click', function (e) {
                const mapData = $(this).closest('.address').find('.address__map');
                const currentLat = mapData.data('lat');
                const currentLng = mapData.data('lng');
                let location = {lat: currentLat, lng: currentLng };

                AddressObj.fullMap.setCenter(location);
            });

        }
    };
    $(document).ready(function () {
        AddressObj.descToggle();
        AddressObj.mapToggle();
        // AddressObj.send();
        AddressObj.init();
    })
})()