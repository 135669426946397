export default function contacts() {
    const contacts = {
        initMap() {
            const mapElement = document.getElementById('contacts__map');
            let  currentLat = $(mapElement).data('lat');
            let  currentLng =  $(mapElement).data('lng');
            if (typeof(mapElement) !== 'undefined' && mapElement !== null) {
                const map = new google.maps.Map(mapElement, {
                    center: {lat: parseFloat(currentLat), lng: parseFloat(currentLng)},
                    zoom: 15,
                    disableDefaultUI: true
                });
                new google.maps.Marker({position: {lat: parseFloat(currentLat), lng: parseFloat(currentLng)}, map: map});
                contacts.resizePositionChange(map);
            }
        },
        resizePositionChange(map) {
            // $(window).resize(function() {
                // const windowWidth = $(this).width();
                // if (windowWidth > 480 && windowWidth < 992) {
                //     map.setCenter(new google.maps.LatLng(50.4281388, 30.6020647));
                // } else if (windowWidth >= 320 && windowWidth <= 480) {
                //     map.setCenter(new google.maps.LatLng(50.4281388, 30.6018647));
                // } else {
                //     map.setCenter(new google.maps.LatLng(50.4281388, 30.6022647));
                // }
            // });
        },
        getCenterPosition() {
            // const windowWidth = $(window).width();
            // if (windowWidth > 480 && windowWidth < 992) {
            //     return { lat: 50.4281388, lng: 30.6020647 };
            // } else if (windowWidth >= 320 && windowWidth <= 480) {
            //     return { lat: 50.4281388, lng: 30.6018647 };
            // } else {
            //     return { lat: 50.4281388, lng: 30.6022647 };
            // }
        }
    };
    $(document).ready(() => {
        contacts.initMap();
    });
};
