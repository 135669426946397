import 'bootstrap';
import 'bootstrap-select';
import 'slick-carousel'

import contacts from './components/blocks/contacts/contacts';
import sliderCertificate from './components/constructor/slider_certificate/slider_certificate';
import sliderTeam from './components/constructor/slider_team/slider_team';
import reviews from './components/constructor/reviews/reviews';
import './components/ui/services_gallery/services_gallery'
import './components/constructor/video/video'
import './components/blocks/standard_prod/standard_prod'
import './components/blocks/order_modal/order_modal'
import './components/ui/selectpicker/selectpicker'
import './components/blocks/filters/filters'
import './components/ui/view_toggle/view_toggle'
import './components/blocks/header/header'
import './components/blocks/address/address'
import './components/blocks/services_prod/services_prod'
import './components/blocks/home_slider/home_slider'
import './components/blocks/home_video/home_video'

sliderCertificate();
sliderTeam();
reviews();
contacts();

$(document).ready(function () {
    $('[data-toggle="tooltip"]').tooltip()
});