export default (function viewToggle() {
    const viewToggleObj = {
        init() {
            $(document).on('click', '.js--view_list', function () {
                viewToggleObj.listViewOn()
            });
            $(document).on('click', '.js--view_table', function () {
                viewToggleObj.tableViewOn()

            });

        },
        listViewOn() {
            $('.js--view_table').removeClass('active');
            $('.js--view_list').addClass('active');
            $('body').addClass('list_view');
            sessionStorage.setItem('list_view', 'true');

        },
        tableViewOn() {
            $('.js--view_list').removeClass('active');
            $('.js--view_table').addClass('active');
            $('body').removeClass('list_view');
            sessionStorage.setItem('list_view', 'false');

        },
        checkStorage() {
            const ViewValue = sessionStorage.getItem('list_view');
            if (ViewValue === null || ViewValue == 'false') {
                viewToggleObj.tableViewOn()
            } else {
                viewToggleObj.listViewOn()
            }
        }

    };
    $(document).ready(function () {

        if ($('body#category').length) {
            viewToggleObj.init();
            viewToggleObj.checkStorage();
        }

    })
})()