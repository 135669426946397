export default (function header() {
    const HeaderObj = {
        fixed() {
            const minBreakpoint = 992;
            const $headerEl = $('.js--header');
            const headerOfTop = 64;
            if (window.matchMedia(`(min-width: ${minBreakpoint}px)`).matches) {
                if ($(window).scrollTop() >= headerOfTop) {
                    $headerEl.addClass('header--fixed');
                    $headerEl.css('top', `-${headerOfTop}px`)
                } else {
                    $headerEl.removeClass('header--fixed');
                    $headerEl.css('top', 0)

                }
            } else {
                $headerEl.removeClass('header--fixed');
                $headerEl.css('top', 0)
            }
        }
    };
    $(document).ready(function () {
        HeaderObj.fixed();
        $(window).resize(function () {
            HeaderObj.fixed();
        });
        $(window).scroll(function () {
            HeaderObj.fixed();
        });
    })
})()