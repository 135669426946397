export default (function video() {

    const videoObj = {
        control() {
            const $videoEl = $('.js--video');

            $videoEl.each(function () {
                let currentDuration = 0;
                $(this)[0].addEventListener('loadedmetadata', function () {
                    let convertMillis = ($(this)[0].duration) * 1000;
                    currentDuration = videoObj.millisToMinutesAndSeconds(convertMillis);
                    $(this).closest('.video').find('.js--duration .video__duration_min').text(currentDuration[0]);
                    $(this).closest('.video').find('.js--duration .video__duration_sec').text(currentDuration[1]);

                });
            });

            $('.video__play').on('click', function () {
                $(this).closest('.video').find('.js--video')[0].play();
                $(this).closest('.video').find('.js--video')[0].setAttribute('controls', 'controls');
                $(this).addClass('d-none');
            })
        },

        millisToMinutesAndSeconds(millis) {
            var minutes = Math.floor(millis / 60000);
            var seconds = ((millis % 60000) / 1000).toFixed(0);
            return [minutes, seconds]
        }
    };
    $(document).ready(function () {
        videoObj.control();
    })
})()