
export default (function serviceGallery() {
    $(document).ready(() => {
        $('.js--slider').each(function () {
            $(this).slick({
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                mobileFirst: true,
                prevArrow: $(this).closest('.services_gallery').find('.arrows').find('.js--arrows__prev'),
                nextArrow: $(this).closest('.services_gallery').find('.arrows').find('.js--arrows__next'),
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 4,
                        }
                    }
                ]
            });
        });
    });
})()