export default (function filters() {
    const filtersObj = {
        toggleFilter() {
            $(document).on('click', '.PM_ASCriterionsGroupTitle', function () {
                $(this).next('.PM_ASCriterionsGroupOuter').toggle();
                $(this).toggleClass('hide');
            })
        }
    };
    $(document).ready(function () {
        filtersObj.toggleFilter()
    })
})()