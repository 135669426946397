export default (function servicesProd() {
    const servicesProdlObj = {
        closePrice() {
            $('.js--close_price').on('click', function () {
                $(this).closest('.banner_fixed').attr('hidden', true)
            })
        }
    };
    $(document).ready(function () {
        servicesProdlObj.closePrice();

    })
})()