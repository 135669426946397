export default function sliderCertificate () {
    const sliderCertificate = {
        initSlider () {
            const $slider = $('.js--slider_certificate__slider');

            $slider.slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                infinite: false,
                dots: false,
                arrows: true,
                nextArrow: $('.slider_certificate__arrow--next'),
                prevArrow: $('.slider_certificate__arrow--prev'),
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
        },
    };
    $(document).ready(() => {
        sliderCertificate.initSlider();
        $('[data-fancybox="images"]').fancybox();
    });
};