import 'bootstrap-select';

export default (function selectpicker() {
    const selectpickerObj = {
        init() {
            $('.selectpicker').selectpicker();
        }
    };
    $(document).ready(function () {

        selectpickerObj.init();
    })
})()