export default (function homeVideo() {
    const video = {
        init() {
            $('.js--home_video').on('click', function (e) {
                e.stopPropagation()
                e.preventDefault()

                $(this).addClass('home_video--show')
                // $(this).find('video').attr('controls', true);
                // $(this).find('video')[0].play()
            })
            $('.js--video_close').on('click', function (e) {
                e.stopPropagation()
                e.preventDefault()
                // $('.js--home_video video')[0].pause()
                // $('.js--home_video video').removeAttr('controls');
                $('.js--home_video').removeClass('home_video--show')


            })

            $('.services_board__item').hover(
                function() {
                    $(this).find( '.services_board__text_toggle' ).stop().slideUp( );

                    $(this).find('.services_board__text_toggle' ).slideDown( 350 );
                }, function() {
                    $(this).find('.services_board__text_toggle' ).stop().slideDown( );

                    $(this).find( '.services_board__text_toggle' ).slideUp( 350 );
                }
            );

        }
    };
    $(document).ready(function () {
        video.init();
    })
})()