export default (function standardProd() {
    const slider = {
        init() {
            $('.js--comments').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: $('.comment_arrow .js--prev'),
                nextArrow: $('.comment_arrow .js--next'),
                adaptiveHeight: true
            });
            $('.js--prod_gallery').slick({
                infinite: true,
                slidesPerRow: 3,
                slidesToScroll: 1,
                rows: 2,
                mobileFirst: true,
                prevArrow: $('.standard_prod__gallery .js--next'),
                nextArrow: $('.standard_prod__gallery .js--prev'),
            });

            $('.js--prod_slider').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                asNavFor: '.js--prod_thumb',
                mobileFirst: true,
                prevArrow: $('.js--prod_slider').closest('.standard_prod__images').find('.arrows').find('.js--arrows__next'),
                nextArrow: $('.js--prod_slider').closest('.standard_prod__images').find('.arrows').find('.js--arrows__prev'),
            });


            $('.js--prod_thumb').slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                asNavFor: '.js--prod_slider',
                focusOnSelect: true,
                prevArrow: $('.js--standard_prod_prev'),
                nextArrow: $('.js--standard_prod_next'),

            });


            $('.js--prod_slider_modal').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                asNavFor: '.js--prod_thumb_modal',
                mobileFirst: true,
                arrows: false
            });
            $('.js--prod_thumb_modal').slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                asNavFor: '.js--prod_slider_modal',
                focusOnSelect: true,
                prevArrow: $('.gallery_popup .standard_prod__thumbs_prev'),
                nextArrow: $('.gallery_popup .standard_prod__thumbs_next'),

            });


        }
    };
    $(document).ready(function () {
        $('.modal').on('shown.bs.modal', function (e) {
            $('.js--prod_gallery').resize();
        })
        slider.init();
    })
})()