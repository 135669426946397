export default function reviews () {
    const reviews = {
        initSlider () {
            const $slider = $('.js--reviews_slider');

            $slider.slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                dots: false,
                arrows: true,
                nextArrow: $('.reviews__arrow--next'),
                prevArrow: $('.reviews__arrow--prev'),
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            arrows: false,
                            dots: true,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            arrows: false,
                            dots: true,
                        }
                    }
                ]
            });
        },
    };
    $(document).ready(() => {
        reviews.initSlider();
    });
};