export default (function homeSlider() {
    const slider = {
        init() {
            $('.js--home_slider').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                arrows: false,
                infinity: true,
                adaptiveHeight: true,
                fade: true,
                speed: 3100,
                // cssEase: 'cubic-bezier(0.4, 0, 1, 1)',
            });
        }
    };
    $(document).ready(function () {
        slider.init();
    })
})()